import * as RxOp from "rxjs/operators";
import {
    Models,
    CompleteDE,
    DownloadFinalDE,
    MeasurementMode,
    ModemDE,
    NetworkQualitySDK,
    NQValueDE,
    PingDE,
    PrepareResultDE,
    UploadFinalDE,
    NQHint,
    NQValueKind,
    BenchmarkDE,
    createPrepareResult,
    createBenchmarkIntermediate,
    createBenchmarkFinalAvailable,
    DownloadDE,
    createDownloadIntermediate,
    createDownloadFinal,
    UploadDE,
    createPingFinal,
    createPingIntermediate,
    createUploadFinal,
    createUploadIntermediate,
    createModemIntermediateResolving,
    createModemIntermediateExecuting,
    createModemFinalAvailable,
    SpeedtestState,
    createHints,
    createModemFinalDidNotRun,
    ModemNotRunReason
} from "@visonum/network-quality-sdk";
import { concat, EMPTY, from, interval, Observable, of, throwError } from "rxjs";
import { logger } from "../../helper/logger";
import { freeze } from "immer";
import * as R from "ramda";
import { createModemFinalFailed } from "@visonum/network-quality-sdk";

export class SyntheticNetworkQuality extends NetworkQualitySDK {

    constructor(private readonly mode: MeasurementMode, private readonly intervalSec: number) {
        super();

        logger.info(`SyntheticNetworkQuality constructor. Mode = ${mode}`);
    }

    public speedtest() {
        const self = this;
        return this.prepare().pipe(
            RxOp.mergeMap((prepareResult, index) => {
                return concat(
                    of(prepareResult),
                    self.benchmark(prepareResult),
                    self.download(prepareResult),
                    self.upload(prepareResult),
                    self.ping(prepareResult),
                    self.complete(prepareResult),
                    self.modem(prepareResult),
                    self.hintsForResult(prepareResult)
                );
            }),
            RxOp.catchError(err => {
                return throwError(() => err);
            }),
        );
    }

    public prepare(): Observable<PrepareResultDE> {
        return zipWithTimer(of(this.createPrepareResult()), 4 * this.intervalSec);
    }

    public benchmark(prepareResult: PrepareResultDE): Observable<BenchmarkDE> {
        return zipWithTimer(from(this.getBenchmarkValues()), this.intervalSec);
    }

    private download(prepareResult: PrepareResultDE): Observable<DownloadDE> {
        const cfg = prepareResult.config.download;
        const count = Math.ceil(cfg.duration / cfg.interval);
        return zipWithTimer(from(this.getDownloadValues(count)), cfg.interval);
    }

    public calcDownload(data: Models.CalcDownloadRequestModel): Observable<DownloadFinalDE> {
        return zipWithTimer(of(R.last(this.getDownloadValues(1)) as DownloadFinalDE), this.intervalSec);
    }

    private upload(prepareResult: PrepareResultDE): Observable<UploadDE> {
        const cfg = prepareResult.config.upload;
        const count = Math.ceil(cfg.duration / cfg.interval);
        return zipWithTimer(from(this.getUploadValues(count)), cfg.interval);
    }

    public calcUpload(data: Models.CalcUploadRequestModel): Observable<UploadFinalDE> {
        return zipWithTimer(of(R.last(this.getUploadValues(1)) as UploadFinalDE), this.intervalSec);
    }

    public ping(prepareResult: PrepareResultDE): Observable<PingDE> {
        const cfg = prepareResult.config.ping;
        const count = Math.ceil(cfg.duration / cfg.interval);
        return zipWithTimer(from(this.getPingValues(count)), cfg.interval);
    }

    public complete(prepareResult: PrepareResultDE): Observable<CompleteDE> {
        return zipWithTimer(of(this.getCompleteValue()), this.intervalSec);
    }

    public abort(prepareResult: PrepareResultDE): Observable<never> {
        return EMPTY;
    }

    public modem(prepareResult: PrepareResultDE): Observable<ModemDE> {
        const resolvingCount = 4;
        const executingCount = 5;
        const interval = 0.4;
        return zipWithTimer(from(this.getModemValues(resolvingCount, executingCount)), interval);
    }

    public hints(speedtestId?: string): Observable<NQHint[]> {
        return of(this.getHints());
    }

    private hintsForResult(prepareResult: PrepareResultDE): Observable<NQValueDE> {
        return this.hints(prepareResult.init.speedtest.id).pipe(
            RxOp.map(res => createHints(res))
        )
    }

    public print(printRequestModel: Models.PrintRequestModel): Observable<Blob> {
        return EMPTY;
    }

    public updateValue(key: Models.ValueKey, command: Models.ValueCommand): Observable<number> {
        return of(2);
    }

    protected getConfigData(): Models.ConfigResponseDataModel {
        return freeze({
            version: "0.8.2",
            remotePortDetectionUrl: `remotePortDetection`,
            ipDetectionUrl: {
                ipv4: `ipDetection/ipv4`,
                ipv6: `ipDetection/ipv6`,
            },
            download: {
                numStreams: 6,
                duration: 2,
                interval: 0.1,
            },
            upload: {
                duration: 3,
                interval: 0.75,
                maxBytes: 10000000,
            },
            ping: {
                duration: 1,
                interval: 0.8,
            },
            cpuBenchmark: true,
            webBenchmark: false,
            tcpRetrans: true,
            isUnderMaintenance: false,
        });
    }

    protected getInitData(): Models.InitResponseDataModel {
        return freeze({
            "speedtest": {
                "id": "t4k22dykkdck",
                "standAloneMode": false,
                "parentSpeedtestId": null,
                "clientVersion": "2.21.14",
                "startTime": "2023-09-13 19:43:15",
                "servers": {
                    "downloadServers": {
                        "dualstack": [
                            `http://localhost/download-dualstack-1`,
                            `http://localhost/download-dualstack-2`
                        ],
                        "ipv6": [
                            `http://localhost/download-ipv6-1`,
                            `http://localhost/download-ipv6-2`
                        ]
                    },
                    "uploadServer": {
                        "dualstack": `http://localhost/upload-dualstack-1`,
                        "ipv6": `http://localhost/upload-ipv6-1`
                    },
                    "pingServer": {
                        "dualstack": `http://localhost/ping-dualstack-1`,
                        "ipv6": `http://localhost/ping-ipv6-1`
                    },
                    "wsPingServer": {
                        "dualstack": "wss://speedtest-21.vodafone.anw.net/ping/",
                        "ipv6": "wss://speedtest-21v6.vodafone.anw.net/ping/"
                    },
                    "pageSpeedServer": []
                }
            },
            "connection": {
                "ip": "127.0.0.1",
                "ipVersion": Models.ConnectionModelIpVersionEnum.V4,
                "remotePort": 0,
                "isp": "Vodafone",
                "isCustomer": false,
                "ispFootprint": "UM",
                "isVpnActive": false,
                "vpnDetectdBy": Models.ConnectionModelVpnDetectdByEnum.Customer
            },
            "modem": {
                // "name": "FRITZ!Box 6660",
                // "type": "TG6442VF",
                "provisionedDownloadSpeed": 115000000,
                // "bookedDownloadSpeedMax": 1000000000,
                "bookedDownloadSpeedAvg": 850000000,
                "bookedDownloadSpeedMin": 600000000,
                "provisionedUploadSpeed": 56710000,
                // "bookedUploadSpeedMax": 50000000,
                "bookedUploadSpeedAvg": 35000000,
                "bookedUploadSpeedMin": 15000000
            },
            "cmts": {
                "vendor": "Arris",
                "isModemTestAvailable": true
            },
            "client": {
                "id": "uuid123",
                "userAgent": null,
                "browser": {
                    "name": "TestBrowser",
                    "version": "1.1.1",
                    "isCurrent": false
                },
                "os": {
                    "name": "testOS",
                    "version": "2.2.2",
                    "isCurrent": true
                },
                "device": {
                    "id": "Samsung;",
                    "vendor": "Samsung",
                    "model": "A33 5G",
                    "deviceType": Models.DeviceModelDeviceTypeEnum.Mobile,
                    "inches": null,
                }
            },
            "location": {
                "countryCode": "DE"
            },
            "communication": {
                "modemSwap": false,
            },
            "partialService": {
                "downloadIndex": 0,
                "uploadIndex": 0,
            }
        });
    }

    protected isIPv6(): boolean {
        return true;
    }

    protected createPrepareResult(): PrepareResultDE {
        return createPrepareResult(this.getConfigData(), this.getInitData(), this.isIPv6());
    }

    protected getBenchmarkValues = (): BenchmarkDE[] => {
        return freeze([
            createBenchmarkIntermediate(100),
            createBenchmarkFinalAvailable(100, 200),
        ]);
    }

    protected getDownloadValues = (count: number): DownloadDE[] => {
        return freeze(
            R.range(0, count)
                .map(i => createDownloadIntermediate((90 + i) * 1e6) as DownloadDE)
                .concat([createDownloadFinal(460e6, 3e6) as DownloadDE])
        );
    }

    protected getUploadValues = (count: number): UploadDE[] => {
        return freeze(
            R.range(0, count)
                .map(i => createUploadIntermediate((15 + i) * 1e6) as UploadDE)
                .concat([createUploadFinal(45e6, 1e6) as UploadDE])
        );
    }

    protected getPingValues = (count: number): PingDE[] => {
        return freeze(
            R.range(0, count)
                .map(i => createPingIntermediate((5 + i) * 1e-3) as PingDE)
                .concat([createPingFinal(20e-3, 1e-3) as PingDE])
        );
    }

    protected getCompleteValue = (): CompleteDE => {
        return freeze({ kind: NQValueKind.Complete });
    }

    protected getModemValues = (resolvingCount: number, executingCount: number): ModemDE[] => {
        return freeze([
            ...R.range(0, resolvingCount).map(i => createModemIntermediateResolving()),
            ...R.range(0, executingCount).map(i => createModemIntermediateExecuting()),
            // createModemFinalAvailable(1000e6, this.getSpeedtestStateValue(), false, Models.SnmpResultModelConnectionTypeEnum.Lan),
            // createModemFinalFailed("Failed", this.getSpeedtestStateValue(), false, Models.SnmpResultModelConnectionTypeEnum.Lan),
            createModemFinalDidNotRun(ModemNotRunReason.NotACustomer, this.getSpeedtestStateValue(), false, Models.SnmpResultModelConnectionTypeEnum.Lan, null, null, null),
        ]);
    }

    protected getSpeedtestStateValue(): SpeedtestState {
        return {
            downloadClient: false,
            uploadClient: true,
            downloadUdp: null,
        }
    }

    protected getHints(): NQHint[] {
        return freeze([
            { id: 13 },
            { id: 0 },
            { id: 1 },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
            { id: 11 },
            { id: 12 },
            { id: 14 },
            { id: 15 },
            { id: 16 },
            { id: 17 },
            { id: 25 },
            { id: 26 },
            { id: 30 },
            { id: 31 },
            { id: 32 },
            { id: 33 },
            { id: 34 },
            { id: 36 },
        ]);
    }
}

function zipWithTimer<T extends NQValueDE>(observable: Observable<T>, intervalSec: number): Observable<T> {
    if (intervalSec === 0) {
        return observable;
    }

    const timer = interval(intervalSec * 1e3);
    return observable.pipe(
        RxOp.zipWith(timer),
        RxOp.map(v => v[0])
    )
}