import { createSlice } from "@reduxjs/toolkit";
import { createNqError } from "@visonum/network-quality-sdk";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { AppThunk } from "../../store";
import { reportNQError } from "../errors/errorSlice";
import { logger } from '../../helper/logger';
import { NQRecord } from '../history/types';
import getSpeedtestId from "../../helper/utils/getSpeedtestId";
import { VARIANT } from "../../config";
import { printRequest2 } from "./printRequest2";
import { printRequest3 } from "./printRequest3";
import { plainDateToDate } from "../../types";

export enum PrintStage {
    None = "None",
    Request = "Request",
    SucceededResponse = "SucceededResponse",
}

export type PrintState = {
    kind: PrintStage.None,
} | {
    kind: PrintStage.Request,
} | {
    kind: PrintStage.SucceededResponse,
}

const initialState = {
    kind: PrintStage.None
} as PrintState;

export const printReport = (record: NQRecord): AppThunk<Promise<void>> => async (dispatch, getState) => {
    if (getState().print.kind !== PrintStage.None) {
        return;
    }
    dispatch(printSlice.actions.request());
    const printRequest = VARIANT == "SPLUS3" ? printRequest3 : printRequest2
    try {
        const blob = await printRequest(record);
        dispatch(printSlice.actions.succeededResponse());
        const fileName = `speedtestplus-${format(plainDateToDate(record.date), "yyyy-MM-dd")}-${getSpeedtestId(record.prepareResult)}.pdf`;
        logger.info(`Saving file: ${fileName}`);
        FileSaver.saveAs(blob, fileName);
        dispatch(printSlice.actions.reset());
    } catch (err) {
        logger.error("Print Report", err);
        dispatch(reportNQError(createNqError("Cl-12", "Print Report Saving Error", err)));
        dispatch(printSlice.actions.reset());
    }
}

export const printSlice = createSlice({
    name: 'print',
    initialState,
    reducers: {
        reset: (state) => { state.kind = PrintStage.None },
        request: (state) => { state.kind = PrintStage.Request },
        succeededResponse: (state) => { state.kind = PrintStage.SucceededResponse },
    },
});

export const { } = printSlice.actions;

export default printSlice.reducer;