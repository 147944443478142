import { Models, ModemFinalDE, ModemFinalKind, ModemNotRunReason } from "@visonum/network-quality-sdk";
import { NQRecord } from "../history/types";
import { plainDateToISOString } from "../../types";

const createModemFinal = (modem: ModemFinalDE): Models.ReportModelModemFinal => {
    const modemFinal = modem.modemFinal;
    switch (modemFinal.kind) {
        case ModemFinalKind.ModemFinalAvailable:
            return {
                kind: ModemFinalKind.ModemFinalAvailable,
                value: modemFinal.speed
            };
        case ModemFinalKind.ModemFinalDidNotRun:
            const createModemFinalDidNotRun = (): Models.ReportModelModemFinal => {
                switch (modemFinal.reason) {
                    case ModemNotRunReason.NotNeeded:
                        return {
                            kind: ModemFinalKind.ModemFinalDidNotRun,
                            reason: Models.ModemFinalDidNotRunModelReasonEnum.NotNeeded,
                        };
                    case ModemNotRunReason.NotAvailable:
                        return {
                            kind: ModemFinalKind.ModemFinalDidNotRun,
                            reason: Models.ModemFinalDidNotRunModelReasonEnum.NotAvailable,
                        };
                    case ModemNotRunReason.NotACustomer:
                        return {
                            kind: ModemFinalKind.ModemFinalDidNotRun,
                            reason: Models.ModemFinalDidNotRunModelReasonEnum.NotACustomer,
                        };
                }
            }
            return createModemFinalDidNotRun();
        case ModemFinalKind.ModemFinalFailed:
            return {
                kind: ModemFinalKind.ModemFinalFailed,
            };
    }
}


export const createPrintRequestModel = (record: NQRecord, lang: Models.LanguageKey, tips: Models.ReportModelTip[] | null): Models.PrintRequestModel => {
    const modemFinal = createModemFinal(record.modemFinal);

    const isIPv6 = (ip: string) => {
        return ip.includes(":");
    }

    return {
        lang,
        report: {
            speedtest: {
                ...record.prepareResult.init.speedtest,
                timestamp: plainDateToISOString(record.date),
                tips
            },
            communication: {
                plannedImprovement: record.prepareResult.init.communication.plannedImprovement ?? undefined,
            },
            connection: {
                ...record.prepareResult.init.connection,
                ipVersion: isIPv6(record.prepareResult.init.connection.ip) ? Models.ConnectionModelIpVersionEnum.V6 : Models.ConnectionModelIpVersionEnum.V4,
            },
            modem: record.prepareResult.init.modem,
            client: {
                device: record.prepareResult.init.client.device,
            },
            cmts: record.prepareResult.init.cmts,
            result: {
                downloadFinal: {
                    value: record.downloadFinal.speed,
                    jitter: record.downloadFinal.jitter
                },
                uploadFinal: {
                    value: record.uploadFinal.speed,
                    jitter: record.uploadFinal.jitter
                },
                pingFinal: {
                    value: record.pingFinal.pingTime,
                    jitter: record.pingFinal.jitter
                },
                modemFinal
            }
        }
    };
}
