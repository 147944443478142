import { Models, NQHint } from "@visonum/network-quality-sdk";
import { getCurrentLang } from "../../helper/utils";
import { NQRecord } from "../history/types";
import { PartialServiceState, getInputFromNqRecord, getPartialServiceState } from "../partialService/partialServiceUtils";
import { createPrintRequestModel } from "./createPrintRequestModel";
import { lastValueFrom } from "rxjs";
import { getSdk } from "../../networkQuality";
import { logger } from "../../helper/logger";
import { LangName } from "../../types";
import { hintIdsToHintObjects, serverHintsToClientHintIds } from "../hints/hintFunctions";

export const printRequest2 = async (record: NQRecord): Promise<Blob> => {
    const lang = getCurrentLang();
    const tips = getTips(lang, getPartialServiceState(getInputFromNqRecord(record)), record);
    const printRequestModel = createPrintRequestModel(record, lang as Models.LanguageKey, tips);

    try {
        return await lastValueFrom(getSdk().print(printRequestModel));
    } catch (err) {
        logger.error("printRequest", err);
        throw err;
    }
}

const getTips = (lang: LangName, partialServiceState: PartialServiceState, record: NQRecord): Models.ReportModelTip[] | null => {
    if (record.hints === null) {
        return null;
    }

    const nqHints = serverHintsToClientHintIds(record.hints.map(id => ({ id } as NQHint)), partialServiceState);
    return hintIdsToHintObjects(nqHints, record, lang)
            .map(h => ({
                version: Models.ReportModelTipVersionEnum.V2,
                id: h.id,
                title: h.title,
                teaserTitle: h.teaserTitle,
                teaser: h.teaser,
                contentTitle: h.contentTitle,
                content: h.content,
            }));
}