import MarkdownIt from "markdown-it";
import { isRunningInBrowser } from "./ssr";
import Renderer from "markdown-it/lib/renderer";

const md = new MarkdownIt({ html: true, breaks: true });

// Remember old renderer, if overridden, or proxy to default renderer
const defaultRender = md.renderer.rules.link_open || md.renderer.rules.link_close || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
}

const inlineMarkdownRender = (content: string): string => {
    return md.renderInline(content, "inline");
}

const linkOpener: Renderer.RenderRule = (tokens, idx, options, env, self): string => {
    return "";
}

if (isRunningInBrowser()) {
    md.renderer.rules.link_open = linkOpener;
    md.renderer.rules.link_close = linkOpener;
}

export default inlineMarkdownRender;