import styled from "@emotion/styled";
import NavigationButton from "./NavigationButton";
import { CommonButtonDiv, DefaultButtonDiv } from "./Buttons";
import * as R from "ramda";
import t from "../../../helper/t";

import infoCircleSvg from "../../../../assets/icons/splus3/info-circle-mid-gradient1.svg";
import closeDialogSvg from "../../../../assets/icons/splus3/close-dialog.svg";
import closeBlackSvg from "../../../../assets/icons/splus3/close-black.svg";

export enum ModalDialogButtons {
    None,
    Ok,
    Remove,
    RemoveYesNo,
    TipClose,
    TipCancel,
    MeasurementStopContinue,
}

export enum ModalDialogResult {
    Cancel,
    Ok,
    Remove,
    No,
    GoToTip,
    Close,
    StopMeasurement,
    ContinueMeasurement,
}

const RootDiv = styled.div(({ theme }) => ([
    theme.splus3.background.dialog,
    {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        zIndex: 1000,
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        opacity: 1,
        transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
]));

interface CardProps {
    withHeader: boolean;
}

const Card = styled.div<CardProps>(({ withHeader, theme }) => ([
    theme.splus3.background.white,
    withHeader ? {
        maxWidth: 1024,
        paddingTop: 48,
        paddingBottom: 48,
        paddingLeft: 48,
        paddingRight: 48,
        width: "100%",
    } : {
        padding: 0,
        minWidth: 120,
    },
    {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        border: "none",
        flexWrap: "wrap",
        textAlign: "left",
        wordWrap: "break-word",

        margin: 12,
        rowGap: 24,
        borderRadius: 6,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                borderRadius: 0,
                margin: 0,
                rowGap: 0,
                padding: 0,
            }
        ],
    },
]));

const HeaderContainer = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        rowGap: 14,
        alignItems: "center",
        textAlign: "center",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                padding: 12,
                textAlign: "left",
                alignItems: "flex-start",
            }
        ],
    }
]));

const HeaderImg = styled.img(({ theme }) => ([
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none"
            }
        ],
    }
]));

const HeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.black,
    theme.splus3.typography.font32Bold,
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.large,
        ],
    }
]));

const SeparatorDiv = styled.div(({ theme }) => ([
    theme.splus3.background.monochrome3,
    {
        display: "none",
        height: 1,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "initial"
            }
        ],
    }
]));

const ContentDiv = styled.div<CardProps>(({ theme, withHeader }) => ([
    theme.splus3.foreground.black,
    theme.splus3.typography.large,
    {
        lineHeight: "32px",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.small,
            {
                lineHeight: "21px",
            },
            withHeader ? {
                paddingTop: 24,
                paddingBottom: 24,
                paddingLeft: 16,
                paddingRight: 16,
            } : {}
        ],
    }
]));

const ButtonsContainer = styled.div(({ theme }) => ([
    {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
        gap: 10,
        
        alignSelf: "center",
        marginLeft: 16,
        marginRight: 16,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                columnGap: 16,
                marginBottom: 24,
            }
        ],
    }
]));

const CloseButtonContainer = styled.div(({ theme }) => ([
    {
        position: "absolute",
        right: 32,
        top: 20,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                right: 12,
                top: 16,
            }
        ],
    }
]));

const CloseImageDesktop = styled.img(({ theme }) => ([
    {
        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "none"
            }
        ],
    }
]));

const CloseImageMobile = styled.img(({ theme }) => ([
    {
        display: "none",
        width: 24,
        height: 24,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            {
                display: "block"
            }
        ],
    }
]));

interface ModalDialogProps {
    header?: string;
    buttons?: ModalDialogButtons;
    onClose?: (result: ModalDialogResult) => void;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ header, buttons = ModalDialogButtons.None, onClose, children }) => {
    const close = (result: ModalDialogResult) => {
        if (!R.isNil(onClose)) {
            onClose(result);
        }
    }

    const buttonsElement = (): JSX.Element[] => {
        switch (buttons) {
            case ModalDialogButtons.None:
                return [];
            case ModalDialogButtons.Ok:
                return [
                    <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.Ok)} isDialog={true}>
                        <DefaultButtonDiv>{t("Ok")}</DefaultButtonDiv>
                    </NavigationButton>
                ];
            case ModalDialogButtons.Remove:
                return [
                    <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.Remove)} isDialog={true}>
                        <DefaultButtonDiv>{t("Löschen")}</DefaultButtonDiv>
                    </NavigationButton>
                ];
            case ModalDialogButtons.RemoveYesNo:
                return [
                    <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.Remove)} isDialog={true}>
                        <DefaultButtonDiv>{t("Ja, löschen")}</DefaultButtonDiv>
                    </NavigationButton>,
                    <NavigationButton onClick={() => close(ModalDialogResult.No)} isDialog={true}>
                        <CommonButtonDiv>{t("Nein")}</CommonButtonDiv>
                    </NavigationButton>
                ];
            case ModalDialogButtons.TipClose:
                return [
                    <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.GoToTip)} isDialog={true}>
                        <DefaultButtonDiv>{t("Zum Tipp")}</DefaultButtonDiv>
                    </NavigationButton>,
                    <NavigationButton onClick={() => close(ModalDialogResult.Close)} isDialog={true}>
                        <CommonButtonDiv>{t("Schließen")}</CommonButtonDiv>
                    </NavigationButton>
                ];
            case ModalDialogButtons.TipCancel:
                return [
                    <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.GoToTip)} isDialog={true}>
                        <DefaultButtonDiv>{t("Zum Tipp")}</DefaultButtonDiv>
                    </NavigationButton>,
                    <NavigationButton onClick={() => close(ModalDialogResult.Close)} isDialog={true}>
                        <CommonButtonDiv>{t("Abbrechen")}</CommonButtonDiv>
                    </NavigationButton>
                ];
            case ModalDialogButtons.MeasurementStopContinue:
                return [
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 10, justifyContent: "center" }}>
                        <NavigationButton isFocused={true} onClick={() => close(ModalDialogResult.StopMeasurement)} isDialog={true}>
                            <DefaultButtonDiv>{t("Messung beenden")}</DefaultButtonDiv>
                        </NavigationButton>
                        <NavigationButton onClick={() => close(ModalDialogResult.ContinueMeasurement)} isDialog={true}>
                            <CommonButtonDiv>{t("Messung fortführen")}</CommonButtonDiv>
                        </NavigationButton>
                    </div>
                ];
        }
    }

    const withHeader = !R.isNil(header) || !R.isNil(onClose);

    return (
        <RootDiv data-cy="modal-dialog">
            <Card withHeader={withHeader}>
                {!R.isNil(header) && (
                    <>
                        <HeaderContainer>
                            <HeaderImg src={infoCircleSvg.src} />
                            <HeaderDiv>{header}</HeaderDiv>
                        </HeaderContainer>
                        <SeparatorDiv />
                    </>
                )}
                <ContentDiv withHeader={withHeader}>{children}</ContentDiv>
                {buttons !== ModalDialogButtons.None && (
                    <ButtonsContainer>
                        {buttonsElement().map((el, index) => <div key={index}>{el}</div>)}
                    </ButtonsContainer>
                )}
                {!R.isNil(onClose) && (
                    <CloseButtonContainer>
                        <NavigationButton onClick={() => close(ModalDialogResult.Cancel)} isDialog={true}>
                            <CloseImageDesktop src={closeDialogSvg.src} alt="close" />
                            <CloseImageMobile src={closeBlackSvg.src} alt="close" />
                        </NavigationButton>
                    </CloseButtonContainer>

                )}
            </Card>
        </RootDiv>
    );
}

export default ModalDialog;