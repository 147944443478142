import { i18n } from "next-i18next";
import router from "next/router";
import * as R from "ramda";
import { LangName } from "../../types";
import { logger } from "../logger";
import { defaultLanguage, getPathByLang, languageList } from "./languageList";

export const changeLanguage = async (lang: LangName, avoidSpeedtest: boolean) => {
  const langPath = getPathByLang(lang) ?? "";

  const getPathname = () => {
    if (router.pathname.includes("[lang]")) {
      return router.pathname.replace("[lang]", langPath);
    }

    return `/${langPath}${router.pathname}`;
  }

  const getNewPath = () => {
    if (avoidSpeedtest && router.pathname.includes("/speedtest")) {
      return `/${langPath}`;
    } else {
      const pathname = getPathname();
      logger.info(`changeLanguage.getNewPath.pathname = "${pathname}"`);
      const queryIndex = router.asPath.indexOf("?");
      const query = queryIndex > 0 ? router.asPath.substring(queryIndex) : "";
      logger.info(`changeLanguage.getNewPath.query = "${query}"`);
      return pathname + query;
    }
  }

  const path = getNewPath().replace("//", "/");
  logger.info(`changeLanguage.path = "${path}"`);
  return await router.push(path, path, { locale: R.isEmpty(langPath) ? undefined : langPath });
}

export const getLocale = (): string => {
  const navigatorLocale = getNavigatorLocale();
  if (navigatorLocale !== null) {
    return navigatorLocale;
  }

  if (languageList.length === 0) {
    throw new Error("languageList is empty.");
  }

  return languageList[0]!;
}

const getNavigatorLocale = (): string | null => {
  const foundLangs = navigator.languages.filter(navLng => {
    const found = languageList.find(lng => {
      return navLng.startsWith(lng);
    });
    return !R.isNil(found);
  });

  const foundLang = foundLangs.length === 0 ? null : foundLangs.map(fl => languageList.find(lng => fl.startsWith(lng))!)[0]!
  return foundLang;
}

export const getCurrentLang = () => {
  const langStr = i18n?.language;
  if (R.isNil(langStr)) {
    return defaultLanguage;
  }
  return langStr as LangName;
}

export const currentLangIs = (lang: LangName): boolean => {
  return getCurrentLang() === lang;
}
