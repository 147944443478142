import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { isSpeedtestRunning } from "../../../features/measurement/utils";
import { changeLanguage } from "../../../helper/utils";
import { useAppSelector } from "../../../hooks";
import { getFullLanguageList, getPathByLang } from "../../../helper/utils/languageList";
import { LangName } from "../../../types";
import { logger } from "../../../helper/logger";

import globeSvg from "../../../../public/img/icons/globe.svg";

interface RootDivProps {
    disabled: boolean;
}

const RootDiv = styled.div<RootDivProps>([
    props => (
        {
            opacity: props.disabled ? 0.4 : 1.0,
        }
    ),
    {
        display: "flex",
        columnGap: 6,
    }
]);

const LanguageSwitch: React.FC<{}> = () => {
    const measurementState = useAppSelector(state => state.measurement);

    const languageList = getFullLanguageList();

    return (
        <RootDiv data-cy="language-switch" disabled={isSpeedtestRunning(measurementState)}>
            <img src={globeSvg.src} />
            <div />
            {languageList.map((lng, index) => <div key={`ls-item-${index}`} style={{display: "flex", columnGap: 6}}>
                <LanguageSwitchItem lang={lng} caption={lng.toUpperCase()} />
                {index < (languageList.length - 1) && <ActiveLinkDiv>|</ActiveLinkDiv>}
            </div>)}
        </RootDiv>
    );
}

interface LinkDivProps {
    isDisabled?: boolean;
}

const LinkDiv = styled.div<LinkDivProps>([
    props => props.theme.typography.regular20,
    props => props.theme.colors.foreground.inactiveLink,
    props => ({
        cursor: props.isDisabled === true ? "default" : "pointer",
    }),
    `
    @media (max-width: 428px) {
      font-size: 16px;
    }
    `
]);

const ActiveLinkDiv = styled(LinkDiv)([
    props => props.theme.typography.bold,
    props => props.theme.colors.white,
    {
        cursor: "default",
    }
]);


interface LanguageSwitchItemProps {
    lang: LangName;
    caption: string;
}

const LanguageSwitchItem: React.FC<LanguageSwitchItemProps> = ({ lang, caption }) => {
    const path = getPathByLang(lang);

    const router = useRouter();
    const measurementState = useAppSelector(state => state.measurement);

    const [isActiveLink, setIsActiveLink] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const isDisabled = isBusy || isSpeedtestRunning(measurementState);

    useEffect(() => {
        setIsActiveLink(router.query?.lang === path)
    }, []);

    return isActiveLink ? (
        <ActiveLinkDiv>
            {caption}
        </ActiveLinkDiv>
    ) : (
        <LinkDiv
            isDisabled={isDisabled}
            onClick={() => {
                if (isDisabled) {
                    return;
                }
                setIsBusy(true);
                changeLanguage(lang, true).then(value => {
                    if (value) {
                        logger.info(`Locale has been changed to ${lang}`)
                    } else {
                        setIsBusy(false);
                    }
                });
            }}>
            {caption}
        </LinkDiv>
    )
}

export default LanguageSwitch;
