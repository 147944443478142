import { css } from "@emotion/react";
import { freeze } from "immer";

const regularFontFamily = "vodafone-regular";
const boldFontFamily = "vodafone-bold";
const liteFontFamily = "vodafone-lite";

const breakpoints = {
  mobile: "@media (min-width: 375px)",
  tablet: "@media (min-width: 768px)",
  desktop: "@media (min-width: 1280px)",
  bigDesktop: "@media (min-width: 1920px)",
  midDesktop: "@media (min-width: 1440px)",
};

/**
 * An app theme. All leaf values in this object are of type SerializedStyles.
 */
export const theme = freeze({
  colors: {
    /* legacy colors */
    vfRed: css`
      color: #e60000;
    `,
    white: css`
      color: #fff;
    `,
    black: css`
      color: #000;
    `,
    grey: css`
      color: #333;
    `,
    lightGrey: css`
      color: #ccc;
    `,
    /* legacy colors end */
    foreground: {
      nboText: css`
        color: #0D0D0D;
      `,
      primary: css`
        color: black;
      `,
      secondary: css`
        color: #333;
      `,
      white: css`
        color: #fff;
      `,
      inactive: css`
        color: #ccc;
      `,
      light: css`
        color: #666;
      `,
      footerTopContent: css`
        color: #fff;
      `,
      ok: css`
        color: #472d00;
      `,
      semiOk: css`
        color: #f80;
      `,
      notOk: css`
        color: #e60000;
      `,
      inactiveLink: css`
        color: #666c76;
      `,
    },
    background: {
      primary: css`
        background-color: white;
      `,
      secondary: css`
        background-color: #ccc;
      `,
      tertiary: css`
        background-color: #333;
      `,
      quaternary: css`
        background-color: black;
      `,
      faqAnswer: css`
        background-color: #f4f4f4;
      `,
      reportHeader: css`
        background-color: #333;
      `,
      footerTopContent: css`
        background-color: #333;
      `,
      tippFooter: css`
        background-color: #333;
      `,
      red: css`
        background-color: #e60000;
      `,
      knowHowBlock: css`
        background-color: #f0f3f7;
      `,
      nboTeaser: css({
        backgroundColor: "#F2F2F2",
      }),
    },
    border: {
      secondary: css`
        border-color: #333;
      `,
    }
  },
  typography: {
    lite: css({
      fontFamily: liteFontFamily,
    }),
    regular: css({
      fontFamily: regularFontFamily,
    }),
    bold: css({
      fontFamily: boldFontFamily,
      fontWeight: 700,
    }),
    h1: css({
      fontFamily: regularFontFamily,
      fontSize: 30,
    }),
    h2: css``,
    h3: css``,
    h4: css``,
    body1: css``,
    body2: css``,
    button: css({
      fontFamily: boldFontFamily,
      fontSize: 16,
      textDecoration: "none",
    }),
    bold14: css({
      fontFamily: boldFontFamily,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "18px",
    }),
    bold16: css({
      fontFamily: boldFontFamily,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "22px",
    }),
    bold20: css({
      fontFamily: boldFontFamily,
      fontSize: 20,
    }),
    bold24: css({
      fontFamily: boldFontFamily,
      fontSize: 24,
    }),
    bold32: css({
      fontFamily: boldFontFamily,
      fontSize: 32,
    }),
    regular20: css({
      fontFamily: regularFontFamily,
      fontSize: 20,
    }),
    regular18: css({
      fontFamily: regularFontFamily,
      fontSize: 18,
      lineHeight: "26px",
    }),
    regular16: css({
      fontFamily: regularFontFamily,
      fontSize: 16,
    }),
    regular14: css({
      fontFamily: regularFontFamily,
      fontSize: 14,
    }),
    regular12: css({
      fontFamily: regularFontFamily,
      fontSize: 12,
    }),
    regular10: css({
      fontFamily: regularFontFamily,
      fontSize: 10,
    }),
    caption: css``,
    value: css({
      fontFamily: boldFontFamily,
      fontSize: 14,
      fontWeight: 400,
    }),
    key: css({
      fontFamily: regularFontFamily,
      fontSize: 14,
      fontWeight: 400,
    }),
    title: css({
      fontFamily: regularFontFamily,
      fontSize: 16,
    }),
    tabletTitle: css(
      {
        fontFamily: regularFontFamily,
        fontSize: 16,
      },
      `@media (min-width: 768px) {font-size: 19px}`
    ),
    regular32: css({
      fontFamily: regularFontFamily,
      fontSize: 32,
    }),
    regular28: css({
      fontFamily: regularFontFamily,
      fontSize: 28,
    }),
    lite18: css({
      fontFamily: liteFontFamily,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "26px",
    }),
    lite28: css({
      fontFamily: liteFontFamily,
      fontSize: 28,
      fontWeight: 400,
      lineHeight: "32px",
    }),
    regular24: css({
      fontFamily: regularFontFamily,
      fontSize: 24,
    }),
    tariffValueText: css({
      fontFamily: regularFontFamily,
      fontSize: 12,
      [breakpoints.tablet]: {
        fontSize: 14,
      },
    }),
    reportTitle: css({
      fontFamily: regularFontFamily,
      fontSize: 26,
      [breakpoints.tablet]: {
        fontSize: 32,
      },
    }),
    faqAnswerText: css({
      fontFamily: regularFontFamily,
      fontSize: "14px",
      lineHeight: "18px",
    }),
    modalDialogText: css({
      fontFamily: regularFontFamily,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "normal",
    }),
    reportHeader: css(
      {
        fontFamily: regularFontFamily,
        fontSize: 16,
      },
      `@media (max-width: 500px) {font-size: 12px}`
    ),
    reportSubHeader: css(
      {
        fontFamily: regularFontFamily,
        fontSize: 12,
      },
      `@media (max-width: 500px) {font-size: 10px}`
    ),
    reportBody: css(
      {
        fontFamily: boldFontFamily,
        fontSize: 18,
      },
      `@media (max-width: 500px) {font-size: 14px}`
    ),
    reportBodyDate: css(
      {
        fontFamily: regularFontFamily,
        fontSize: 14,
      },
      `@media (max-width: 500px) {font-size: 10px}`
    ),
    footerContent: css({
      fontSize: 16,
      fontFamily: boldFontFamily,
    }),
    bigNumbers: css({
      fontSize: 48,
      fontFamily: boldFontFamily,
    }),
    medium: css({
      fontSize: 22,
      fontFamily: regularFontFamily,
    }),

    knowHowTitle: css({
      fontFamily: regularFontFamily,
      fontSize: 32,
      [breakpoints.tablet]: {
        fontSize: 48,
      },
    }),

    knowHowTitleContent: css({
      fontFamily: regularFontFamily,
      fontSize: 26,
      [breakpoints.tablet]: {
        fontSize: 32,
      },
    }),
  },
  breakpoints,
} as const);