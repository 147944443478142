import * as R from "ramda";
import { KeyCode_Enter, getKeyCode } from "../../../helper/utils";
import { useAppSelector } from "../../../hooks";
import { useEffect, useRef } from "react";
import { DialogKind } from "../../../features/dialogManager/dialogManagerSlice";

interface ClickableDivProps {
    onClick?: () => void;
    enabled?: boolean | "auto";
    style?: React.CSSProperties;
    role?: React.AriaRole;
    tabIndex?: number;
    preventDefault?: boolean;
    isFocused?: boolean;
    dataCy?: string;
}

export const ClickableDiv: React.FC<ClickableDivProps> = ({ onClick, enabled = "auto", style, role = "button", tabIndex = 0, preventDefault = false, isFocused = false, dataCy, children }) => {
    const divRef = useRef<HTMLDivElement>(null);

    const onMouseClick = (ev: React.MouseEvent<HTMLElement>) => {
        if (preventDefault === true) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (!R.isNil(onClick)) {
            onClick();
        }
    }

    const onKeyDown = (ev: React.KeyboardEvent) => {
        if (getKeyCode(ev) === KeyCode_Enter) {
            if (preventDefault === true) {
                ev.preventDefault();
                ev.stopPropagation();
            }

            if (!R.isNil(onClick)) {
                onClick();
            }
        }
    }

    const isEnabled = enabled === "auto" ? useAppSelector(state => state.dialogManager).kind === DialogKind.None : enabled;

    useEffect(() => {
        if (isFocused === true && divRef.current !== null) {
            divRef.current?.focus();
        }
    }, [isFocused]);

    return isEnabled ? (
        <div data-cy={dataCy}
            ref={divRef}
            style={{ cursor: "pointer", ...(R.isNil(style) ? {} : style) }}
            onClick={onMouseClick}
            onKeyDown={onKeyDown}
            role={role}
            tabIndex={tabIndex}>
            {children}
        </div>
    ) : (
        <div data-cy={dataCy} style={{ cursor: "pointer", ...(R.isNil(style) ? {} : style) }}>
            {children}
        </div>
    );
}